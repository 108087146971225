import * as types from './mutation-types';

const defaultColors = {
	info: 'blue lighten-2',
	error: 'red lighten-1',
	warning: 'orange lighten-2',
	success: 'green lighten-1',
};

const defaultIcons = {
	info: 'mdi-information-outline',
	error: 'mdi-alert-circle',
	warning: 'mdi-alert',
	success: 'mdi-checkbox-marked-circle-outline',
};

export default {
	namespaced: true,

	state: () => ({
		show: false,
		lock: false,
		title: '',
		message: '',
		color: 'info',
		icon: '',
	}),

	getters: {
		show: (state) => state.show,
		lock: (state) => state.lock,
		title: (state) => state.title,
		message: (state) => state.message,
		color: (state) => state.color,
		icon: (state) => state.icon,
	},

	mutations: {
		[types.SHOW_DIALOG](state) {
			state.show = true;
		},
		[types.HIDE_DIALOG](state) {
			state.show = false;
		},
		[types.SET_LOCK](state, val) {
			state.lock = val;
		},
		[types.SET_TITLE](state, val) {
			state.title = val;
		},
		[types.SET_MESSAGE](state, val) {
			state.message = val;
		},
		[types.SET_COLOR](state, val) {
			state.color = val;
		},
		[types.SET_ICON](state, val) {
			state.icon = val;
		},
	},

	actions: {
		dialog({ commit }, payload) {
			commit(types.SET_TITLE, payload.title);
			commit(types.SET_MESSAGE, payload.message);
			commit(types.SET_COLOR, payload.color);
			commit(types.SET_ICON, payload.icon);
			commit(types.SET_LOCK, payload.lock);
			commit(types.SHOW_DIALOG);
		},
		closeDialog({ commit }) {
			commit(types.HIDE_DIALOG);
		},
		info({ dispatch }, { title, message, lock = false }) {
			dispatch('dialog', {
				title,
				message,
				lock,
				icon: defaultIcons.info,
				color: defaultColors.info,
			});
		},
		error({ dispatch }, { title, message, lock = false }) {
			dispatch('dialog', {
				title,
				message,
				lock,
				icon: defaultIcons.error,
				color: defaultColors.error,
			});
		},
		warning({ dispatch }, { title, message, lock = false }) {
			dispatch('dialog', {
				title,
				message,
				lock,
				icon: defaultIcons.warning,
				color: defaultColors.warning,
			});
		},
		success({ dispatch }, { title, message, lock = false }) {
			dispatch('dialog', {
				title,
				message,
				lock,
				icon: defaultIcons.success,
				color: defaultColors.success,
			});
		},
	},
};
