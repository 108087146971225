import Vue from 'vue';
import Vuex from 'vuex';
import * as types from './mutation-types';

// Modules import

	// Common
	import dialog from './modules/dialog';
	import snackbar from './modules/snackbar';

	// core
	import wizard from './modules/wizard';

Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		defaultDocumentTitle: 'כספן - איתור כספים אבודים',
		appFullscreenLoader: false,
	},
	getters: {
		appFullscreenLoader: (state) => state.appFullscreenLoader,
	},
	mutations: {
		[types.SET_DOCUMENT_TITLE](state, val) {
			document.title = val;
		},
		[types.SHOW_FULLSCREEN_LOADER](state) {
			state.appFullscreenLoader = true;
		},
		[types.HIDE_FULLSCREEN_LOADER](state) {
			state.appFullscreenLoader = false;
		},
	},
	actions: {
		appCreatedHook({ state, commit }) {
			commit(types.SET_DOCUMENT_TITLE, state.defaultDocumentTitle);
		},
		showFullscreenLoader({ commit }) {
			commit(types.SHOW_FULLSCREEN_LOADER);
		},
		hideFullscreenLoader({ commit }) {
			commit(types.HIDE_FULLSCREEN_LOADER);
		},
	},
	modules: {
		dialog,
		snackbar,
		wizard,
	},
});
