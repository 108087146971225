export default () => ({
	supplier: null,
	refPage: null,
	utm_campaign: null,
	utm_medium: null,
	utm_source: null,
	recId: null,
	currentStep: 1,
	lastStep: 7,
});
