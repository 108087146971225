import * as types from './mutation-types';

export default {
	[types.SET_SUPPLIER](state, val) {
		state.supplier = val;
	},
	[types.SET_REF_PAGE](state, val) {
		state.refPage = val;
	},
	[types.SET_UTM_CAMPAIGN](state, val) {
		state.utm_campaign = val;
	},
	[types.SET_UTM_MEDIUM](state, val) {
		state.utm_medium = val;
	},
	[types.SET_UTM_SOURCE](state, val) {
		state.utm_source = val;
	},
	[types.SET_REC_ID](state, val) {
		state.recId = val;
	},
	[types.SET_CURRENT_STEP](state, val) {
		state.currentStep = val;
	},
	[types.SET_LAST_STEP](state, val) {
		state.lastStep = val;
	},
};
