import * as WizardService from '@/services/wizard.service';
import * as types from './mutation-types';

export default {
	async initWizard({ dispatch }, { query, refPage }) {
		try {
			dispatch('showFullscreenLoader', null, { root: true });
			dispatch('initMarketingParams', { query, refPage });

			const res = await WizardService.init({ recId: query.recId });

			// TODO: Set data and process configuration
			// TODO: Delete logs
			// TODO: handle Errors

			// console.log('wizard store - axios', res.data);
		} catch (error) {
		// 	console.log('INSIDE ACTION ERROR - ', error.message);
		// 	console.log('INSIDE ACTION ERROR - axios - ', error.response?.data);
		} finally {
			dispatch('hideFullscreenLoader', null, { root: true });
		}
	},
	initMarketingParams({ commit }, { query, refPage }) {
		commit(types.SET_SUPPLIER, query.supplier ?? null);
		commit(types.SET_REF_PAGE, refPage ?? null);
		commit(types.SET_UTM_CAMPAIGN, query.utm_campaign ?? null);
		commit(types.SET_UTM_MEDIUM, query.utm_medium ?? null);
		commit(types.SET_UTM_SOURCE, query.utm_source ?? null);
	},
};
