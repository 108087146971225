<template>
	<v-snackbar
		v-model="show"
		:timeout="timeout"
		:color="color"
		transition="scroll-y-reverse-transition"
		bottom
	>
		<div class="d-flex align-center">
			<v-icon v-if="icon" class="ml-4" v-text="icon"></v-icon>
			<span class="text-body-1" v-text="text"></span>
		</div>
	</v-snackbar>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
	computed: {
		...mapGetters({
			icon: 'snackbar/icon',
			text: 'snackbar/text',
			color: 'snackbar/color',
			timeout: 'snackbar/timeout',
		}),
		show: {
			get() {
				return this.$store.getters['snackbar/show'];
			},
			set(val) {
				this.$store.dispatch('snackbar/closeSnackbar');
			},
		},
	},
};
</script>
