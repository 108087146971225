<template>
	<v-overlay
		:value="appFullscreenLoader"
		:opacity="0.3"
	>
		<v-progress-circular
			indeterminate
			size="100"
			width="7"
			color="ctym-midblue"
			:z-index="150"
		></v-progress-circular>
	</v-overlay>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
	computed: {
		...mapGetters({
			appFullscreenLoader: 'appFullscreenLoader',
		}),
	},
};
</script>
