<template>
	<v-app>

		<the-snackbar></the-snackbar>
		<the-fullscreen-loader></the-fullscreen-loader>

		<v-main>
			<v-scroll-y-transition mode="out-in">
				<router-view />
			</v-scroll-y-transition>
		</v-main>
	</v-app>
</template>

<script>
import TheSnackbar from '@/components/common/TheSnackbar.vue';
import TheFullscreenLoader from '@/components/UI/TheFullscreenLoader.vue';

export default {
	created() {
		this.$store.dispatch('appCreatedHook');
	},
	components: {
		TheSnackbar,
		TheFullscreenLoader,
	},
};
</script>
