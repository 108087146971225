
const sleep = async (delay) => await new Promise((resolve) => setTimeout(resolve, delay));


export const retry = async (fn, args, attempts = 3, delay = 500) => {
	try {

		return await fn.call(this, args);

	} catch (error) {

		if (attempts > 1) {
			attempts--;
			await sleep(delay);
			return await retry(fn, args, attempts, delay);
		}

		throw Error(error);
	}
};
