import axios from 'axios';

export const wizardApi = axios.create({
	baseURL: process.env.VUE_APP_WIZARD_API,
	timeout: 10000,
	headers: {
		'Content-Type': 'application/json',
		Accept: 'application/json',
	},
});

export default axios;