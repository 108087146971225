import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    rtl: true,
    icons: {
        iconfont: 'mdi',
    },
	theme: {
		options: {
			customProperties: true,
		},
		themes: {
			light: {
				'ctym-blue': '#2060b1',
				'ctym-midblue': '#138CD0',
				'ctym-lightblue': '#04bcf0',
				'ctym-grey': '#404040',
			},
			dark: {
				'ctym-blue': '#2060b1',
				'ctym-midblue': '#138CD0',
				'ctym-lightblue': '#04bcf0',
				'ctym-grey': '#404040',
			}
		}
	},
});