import * as types from './mutation-types';

const defaultColors = {
	info: 'blue lighten-2',
	error: 'red lighten-1',
	warning: 'orange lighten-1',
	success: 'green lighten-1',
};

const defaultIcons = {
	info: 'mdi-information-outline',
	error: 'mdi-alert-circle',
	warning: 'mdi-alert',
	success: 'mdi-checkbox-marked-circle-outline',
};

export default {
	namespaced: true,

	state: () => ({
		show: false,
		timeout: 5000,
		text: '',
		color: defaultColors.info,
		icon: defaultIcons.info,
	}),

	getters: {
		show: (state) => state.show,
		timeout: (state) => state.timeout,
		text: (state) => state.text,
		color: (state) => state.color,
		icon: (state) => state.icon,
	},

	mutations: {
		[types.SHOW_SNACKBAR](state) {
			state.show = true;
		},
		[types.HIDE_SNACKBAR](state) {
			state.show = false;
		},
		[types.SET_TEXT](state, val) {
			state.text = val;
		},
		[types.SET_TIMEOUT](state, val) {
			state.timeout = val;
		},
		[types.SET_COLOR](state, val) {
			state.color = val;
		},
		[types.SET_ICON](state, val) {
			state.icon = val;
		},
	},

	actions: {
		alert({ commit }, { text, color, icon }) {
			commit(types.SET_COLOR, color);
			commit(types.SET_ICON, icon);
			commit(types.SET_TEXT, text);
			commit(types.SET_TIMEOUT, 5000);
			commit(types.SHOW_SNACKBAR);
		},
		closeSnackbar({ commit }) {
			commit(types.HIDE_SNACKBAR);
		},
		success({ dispatch }, { text }) {
			dispatch('alert', {
				text,
				color: defaultColors.success,
				icon: defaultIcons.success,
			});
		},
		error({ dispatch }, { text }) {
			dispatch('alert', {
				text,
				color: defaultColors.error,
				icon: defaultIcons.error,
			});
		},
		info({ dispatch }, { text }) {
			dispatch('alert', {
				text,
				color: defaultColors.info,
				icon: defaultIcons.info,
			});
		},
		warning({ dispatch }, { text }) {
			dispatch('alert', {
				text,
				color: defaultColors.warning,
				icon: defaultIcons.warning,
			});
		},
	},
};
