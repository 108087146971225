import Vue from 'vue';
import App from '@/App.vue';
import router from '@/router';
import store from '@/store';
import vuetify from '@/plugins/vuetify';
import axios from '@/http';

// Global css import
import '@/styles/style.scss';

// Http client config
Vue.prototype.$http = axios;

const isLocal = process.env.NODE_ENV === 'development';

Vue.config.productionTip = false;
Vue.config.silent = !isLocal; // true in production
Vue.config.devtools = !!isLocal; // false in production

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
