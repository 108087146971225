import { wizardApi } from '@/http';
import { retry } from '@/services/helpers';


const initAbstraction     = (data) => wizardApi.post('/init', data);
const saveStepAbstraction = (data) => wizardApi.post('/save-step', data);
const finishAbstraction   = (data) => wizardApi.post('/finish', data);

export const init     = async (data) => await retry(initAbstraction, data);
export const saveStep = async (data) => await retry(saveStepAbstraction, data);
export const finish   = async (data) => await retry(finishAbstraction, data);

