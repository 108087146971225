import Vue from 'vue';
import VueRouter from 'vue-router';

import * as routeNames from './route-names';

Vue.use(VueRouter);

const lazyLoad = (view) => () => import(`@/views/${view}.vue`);

const routes = [
	{
		path: '/',
		name: routeNames.Home,
		component: lazyLoad('landing_pages/CompassToYourMoneyLP'),
	},
	{
		path: encodeURI('/המצפן-לכסף-שלך'),
		name: routeNames.CompassToYourMoneyLP,
		component: lazyLoad('landing_pages/CompassToYourMoneyLP'),
	},
	{
		path: '/wizard',
		name: routeNames.Wizard,
		component: lazyLoad('TheWizard'),
	},
	{
		// 404 redirect
		path: '*',
		redirect: '/',
	},
];

const router = new VueRouter({
	mode: 'history',
	routes,
});

export default router;
